import { openModal, closeModal, closeAllModals } from '@rendes/bulma-helpers';
window.openModal      = openModal;
window.closeModal     = closeModal;
window.closeAllModals = closeAllModals;

import errorMessage from '@rendes/error-message';
window.errorMessage   = errorMessage;

import choices from 'choices.js';
window.Choices = choices;
